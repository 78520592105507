import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformacaoDocumentadaConsulta } from '../../../model/informacao-documentada/informacao-documentada-consulta';
import { environment } from '../../../../environments/environment';
import { InformacaoDocumentadaStatus } from '../../../model/informacao-documentada/informacao-documentada-status';

@Injectable({
  providedIn: 'root'
})
export class InformacaoDocumentadaService {

  urlApi = environment.url + "/informacao-documentada"

  constructor(
    private http: HttpClient
  ) { }

  public criarInformacaoDocumentada(formData: FormData): Observable<FormData> {
    return this.http.post<FormData>(`${this.urlApi}`, formData);
  }

  public editarInformacaoDocumentada(formData: FormData): Observable<FormData> {
    return this.http.put<FormData>(`${this.urlApi}`, formData);
  }

  public aprovarInformacao(i: InformacaoDocumentadaStatus): Observable<InformacaoDocumentadaConsulta> {
    return this.http.post<InformacaoDocumentadaConsulta>(`${this.urlApi}/aprovar`, i);
  }

  public reprovarInformacao(i: InformacaoDocumentadaStatus): Observable<InformacaoDocumentadaConsulta> {
    return this.http.post<InformacaoDocumentadaConsulta>(`${this.urlApi}/reprovar`, i);
  }

  public consultaPorId(idInformacaoDocumentada: number): Observable<InformacaoDocumentadaConsulta> {
    return this.http.get<InformacaoDocumentadaConsulta>(`${this.urlApi}/${idInformacaoDocumentada}`);
  }

  public consultaPorUsuario(page: number, size: number): Observable<InformacaoDocumentadaConsulta[]> {
    return this.http.get<InformacaoDocumentadaConsulta[]>(`${this.urlApi}/usuario/${page}/${size}`);
  }

  public consultaPorAprovador(status:string): Observable<InformacaoDocumentadaConsulta[]> {
    return this.http.get<InformacaoDocumentadaConsulta[]>(`${this.urlApi}/aprovador/${status}`);
  }

  public consultaAprovacaoPendentesDoUsuario(status:string): Observable<number> {
    return this.http.get<number>(`${this.urlApi}/aprovacoes/${status}`);
  }

  public consultaInformacoesCadastradas(): Observable<number> {
    return this.http.get<number>(`${this.urlApi}/informacoes-cadastradas`);
  }

  public consultaInformacaoPorStatus(status:string): Observable<InformacaoDocumentadaConsulta[]> {
    return this.http.get<InformacaoDocumentadaConsulta[]>(`${this.urlApi}/status/${status}`);
  }

  public consultaPaginada(paginaAtual: number, totalPorPagina: number): Observable<InformacaoDocumentadaConsulta[]> {
    return this.http.get<InformacaoDocumentadaConsulta[]>(`${this.urlApi}/${paginaAtual}/${totalPorPagina}`);
  }

  public consultarPorIdentificacao(identificacao: string): Observable<InformacaoDocumentadaConsulta[]>{
    return this.http.get<InformacaoDocumentadaConsulta[]>(`${this.urlApi}/identificacao/${identificacao}`);
  }

}
